.topFilter {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	& > div > * {
		margin-right: 1rem;
	}

	.topLeftFilter {
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		label {
			margin-bottom: 0.2rem;
			font-weight: 600;
		}
	}
	.topRightFilter {
		display: flex;
		margin-right: 10px;
	}
	.topRightFilter :last-child {
		margin-right: 0;
	}

	.searchInput {
		width: 350px;
	}
}

.searchContainer {
	display: flex;
	flex-direction: row;
}

.flexCenter {
	display: flex;
	justify-content: center;
}

.skuDetail {
	.wrapper {
		display: flex;
		padding-bottom: 20px;
		overflow-wrap: break-word;

		.sub {
			display: flex;
			flex: 1;
			overflow-wrap: break-word;
		}
	}
}
