.main {
	position: fixed;
	top: 45px;
	bottom: 0;
	width: 24rem;
	right: 0rem;
	background: #ffffff;
	box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
	// overflow-y: auto;
	// transition: 0.5s linear;
	z-index: 2;

	.inventoryInfoContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.title {
		font-size: 1.5rem;
		margin-bottom: 0.5rem;
		font-weight: 300;
	}

	&.open {
		right: 0;
	}

	.paddedRow {
		padding: 1.4rem;
		padding-bottom: 0;
	}
	.row1 {
		margin: 2.5rem 0;
		margin-bottom: 0px;

		.updateForm {
			.button {
				height: 30px;
				padding: 0.4px 20px;
				font-size: 16px;
				border-radius: 40px;
				margin-top: 10px;
				margin-right: 10px;
			}
		}

		.actionButton {
			font-size: 20px;
			margin-top: 10px;
			.button {
				margin-right: 10px;
			}
		}
		.button {
			border-radius: 5px;
		}
		.col {
			display: flex;
			flex-direction: column;
			word-break: break-all;
		}
		:global {
			.title {
				font-size: 1.5rem;
			}
		}
	}

	.noDefaultText {
		margin-top: 55px;
		margin-left: 20px;
		font-size: 24px;
	}

	.noInventoryText {
		margin-top: 15px;
		font-size: 18px;
	}

	.row2 {
		:global {
			.title {
				font-size: 1.5rem;
			}
		}
	}

	.timelineText {
		display: flex;
	}

	.row3 {
		margin: 0.5rem 0;
		overflow-y: auto;
		transition: 0.5s linear;
		height: 300px;
		z-index: 2;

		.timeline {
			margin-top: 10px;
		}

		:global {
			.row3-progress {
				.dot {
					height: 15px;
					width: 15px;
					background-color: rgba(149, 191, 70, 1);
					border-radius: 50%;
					display: block;
					line-height: 0;
					margin: auto;
				}
				.line {
					border-right: 4px solid rgba(149, 191, 70, 1);
					height: 3rem;
					width: 0;
					margin: auto;
					margin-top: -2px;
					line-height: 0;
				}
				.text-col {
					margin-top: -0.5rem;
					.title {
						font-size: 1.1rem;
					}
					.sub {
						color: rgba(120, 119, 119, 1);
						font-size: 0.9rem;
						line-height: 0.9rem;
						margin-bottom: 15px;
					}
				}

				&:last-child {
					.line {
						display: none;
					}
				}
			}
		}
	}
}

.countBox {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 1rem;
	border-bottom: 1px solid #f1eded;
	text-align: center;
}

.countName {
	font-size: 0.8rem;
	color: rgba(0, 0, 0, 0.45);
}

.countValue {
	font-weight: bold;
	font-size: 1.5rem;
}

.trailColumn {
	display: flex;
	justify-content: space-between;
	text-align: center;
	flex-direction: column;
	gap: 0.5rem;
	border-left: 1px solid #f1eded;
}

.inventoryTrail {
	height: 480px;
	overflow: scroll;
	padding-bottom: 0.5rem;
}

.formButton {
	display: flex;
	padding-left: 4rem;
	gap: 1rem;
}
