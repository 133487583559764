.headContainer {
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.supportImg {
	margin: 1rem 0;
	width: 400px;
	height: 400px;
	user-select: none;
	transition: all 1s;
	margin-bottom: 1.7rem;
}
@media screen and (max-width: 990px) {
	h2 {
		font-size: 4vw;
	}
	.supportImgParentDiv {
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	.supportImg {
		width: 100%;
		height: 100%;
	}
	h6 {
		font-size: 3vw;
	}
}
