.addressCard {
	// width: 630px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	padding: 2rem;

	.addressCardInnerContainer {
		display: flex;
		justify-content: space-between;
	}

	.left {
		display: flex;
		column-gap: 1rem;
	}

	.addressId {
		height: 45px;
		width: 45px;
		border-radius: 0.6rem;
		background-color: #e6f7ff;
		color: #1890ff;
		font-size: 1.5rem;
		display: grid;
		place-items: center;
	}

	.addressType {
		text-transform: capitalize;
		line-break: anywhere;
	}

	.address {
		color: #787777;
	}

	.invoicePrefix {
		margin: 0;
	}
	.editButton {
		border-radius: 6px;
	}
}
