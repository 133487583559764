.profilePage {
	padding: 1rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	height: 100%;
	background-color: white;

	.row1 {
		margin: 0.5rem 0 3rem 0;
		.button {
			border-radius: 5px;
		}
		.col {
			display: flex;
			flex-direction: column;
			word-break: break-all;
		}
		.rowData {
			margin-top: 1rem;
			.preferenceRow {
				margin-bottom: 15px;
			}
		}
		:global {
			.title {
				font-size: 16px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.85);
			}
			.description {
				font-size: 14px;
				color: rgba(0, 0, 0, 0.85);
			}
		}
	}

	.row2 {
		margin-top: 1rem;
		font-size: 1rem;

		.secondaryText {
			font-size: 0.825rem;
		}
	}

	.title {
		font-weight: 600;
		margin-bottom: 2rem;
	}

	button {
		margin-right: 0.5pc;
	}
}
