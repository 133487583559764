#rootParentOfTrackingPage {
	background: #f3f1f1;
}
.navbar {
	height: 4rem;
	background-color: #03045e;
	display: flex;
	align-items: center;
	position: sticky;
	z-index: 2;
	width: 100%;
	padding: 1rem;
	flex-direction: row;
	> div {
		margin-left: 0.6rem;
		width: 250px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 250px;
			height: 50px;
			object-fit: contain;
		}
	}
}
h1,
p,
strong {
	padding: 0;
	margin: 0;
}
.parentDivOfTrackingPage {
	h1,
	p,
	h2,
	h3,
	h4,
	h5,
	h6,
	span,
	b {
		margin: 0;
		padding: 0;
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	}
	padding: 0;
	box-sizing: border-box;
	margin: auto;
	max-width: 1200px;
	.gridSection {
		// padding-top: 2rem;
		margin: auto;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		padding-bottom: 2rem;
		column-gap: 3rem;
		height: 100%;
		::-webkit-scrollbar {
			display: none;
		}
		.leftSection {
			display: flex;
			flex-direction: column;
			padding-top: 1rem;
			width: 100%;
			row-gap: 2rem;
			.leftTravelDetails {
				background: #ffffff;
				position: relative;
				border-radius: 8px;
				padding: 1.5rem;
				> :nth-child(1) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 2px solid #d8d6d6;
					> :nth-child(1) {
						font-weight: 700;
						font-size: 30px;
						line-height: 47px;
						color: #5463ff;
					}
					> :nth-child(3) {
						font-weight: 700;
						font-size: 30px;
						line-height: 47px;
						color: #22dabb;
					}
				}
				> :nth-child(2) {
					display: flex;
					justify-content: space-between;
					text-align: left;
					border-bottom: 2px solid #d8d6d6;
					padding-bottom: 0.4rem;
					h1 {
						font-size: 4rem;
						color: #03045e;
						margin: 0;
						padding: 0;
					}
					h3 {
						padding: 0;
						margin: 0;
					}
					> :nth-child(2) {
						padding-top: 1rem;
					}
				}
				> :nth-child(3) {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					padding-top: 0.5rem;
					b {
						font-weight: 700;
						font-size: 22px;
						line-height: 47px;
						color: #22dabb;
					}
				}
			}
			.trailSection {
				height: auto;
				background: #ffffff;
				border-radius: 8px;
				padding: 2rem;
				padding-top: 3rem;
				position: relative;
			}
		}
		.rightSection {
			display: flex;
			flex-direction: column;
			row-gap: 4rem;
			padding-top: 1rem;
			.orderDetails {
				height: 200px;
				background: #ffffff;
				position: relative;
				border-radius: 8px;
				padding: 1rem;
				.topImageSectioninTravelDetails {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 0.2rem;
					.brandLogoImage {
						width: 15rem;
						height: 8rem;
						img {
							height: 8rem;
							width: 8rem;
							object-fit: contain;
						}
					}
					.orderDetailsRightSection {
						display: flex;
						flex-direction: column;
						width: 100%;
						row-gap: 0.2rem;
						text-align: right;
						> h1 {
							font-size: 18px;
						}
						> p {
							font-size: 20px;
							color: #03045e;
							font-weight: 600;
						}
						div {
							width: 100%;
							border-bottom: 2px solid #d8d6d6;
						}
					}
				}
				.orderDetailsBottomDashedDiv {
					width: 100%;
					border-bottom: 2px solid #d8d6d6;
				}
				> :nth-child(3) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-top: 0.3rem;
					> h1 {
						font-size: 18px;
					}
					> p {
						font-size: 20px;
						color: #03045e;
						font-weight: 600;
					}
				}
			}
			.mapSection {
				max-width: 485px;
				height: 365px;
				display: flex;
				justify-content: center;
				align-items: center;
				filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
				border-radius: 8px;
				background-color: #ffffff;
				border: 1px solid #5463ff;
				img {
					width: 100%;
					height: 325px;
					object-fit: contain;
				}
			}
		}
	}
}
.inputField {
	z-index: 1;
	height: 4rem;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	display: flex;
	align-items: center;
	padding: 0.5rem;
	font-size: 1.2rem;
	border: 1px solid #5463ff;
	button {
		width: 100%;
		max-width: 7rem;
		height: 100%;
		background: #5463ff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		border: none;
		font-weight: 700;
		font-size: 1.2rem;
		line-height: 2rem;
		color: #ffffff;
		cursor: pointer;
	}
	input {
		width: 100%;
		height: 100%;
		border: none;
		padding: 0.7rem;
	}
	input:focus {
		outline: none;
	}
	input::placeholder {
		font-size: 1.1rem;
		line-height: 2rem;
		color: #534340;
	}
}

.commonBoxHeading {
	position: absolute;
	top: -2.5rem;
	left: 0;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 39px;
	color: #5463ff;
}

@media screen and (max-width: 900px) {
	.gridSection {
		flex-direction: column-reverse;
		width: 90%;
	}
	.leftSection {
		padding-top: 2rem !important;
	}
}
@media screen and (max-width: 500px) {
	#headingForResponsivness {
		font-size: 3vw;
	}
	#paraForResponsivness {
		font-size: 4vw;
	}
	.leftTravelDetails {
		> :nth-child(2) {
			h5 {
				font-size: 16px;
			}
		}
	}
}

.invalidTrack {
	display: flex;
	padding-top: 8rem;
	margin: auto;
	width: 50%;
	flex-direction: column;
	> :nth-child(2) {
		width: 400px;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			width: 100%;
			height: 500px;
			object-fit: contain;
		}
		b {
			font-size: 22px;
		}
	}
}
