.topHeader {
	padding: 1rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	margin-bottom: 1rem;
}

.searchContainer {
	display: flex;
	flex-direction: column;
	min-width: 300px;
}
