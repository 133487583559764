.details {
	margin-bottom: 10px;
	:global {
		.title {
			font-size: 16px;
		}
		.mainTitle {
			font-size: 1.5rem;
		}
	}

	.col {
		display: flex;
		flex-direction: column;
		word-break: break-all;
	}
}

.gMapModal {
	position: relative;
	height: 100%;
	width: 100%;
	min-height: 60vh;
}

.mapWrap {
	height: 400px;
	width: 100%;
	margin-bottom: 60px;
}

.marker {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	user-select: none;
	transform: translate(-50%, -50%);
	color: #f45936;
	font-size: 1.3rem;
	cursor: default;
	text-align: center;
	&:hover {
		z-index: 1;
		& > .text {
			display: block;
		}
	}

	.text {
		display: none;
		width: 125px;
		font-size: 11px;
		font-weight: 600;
		padding: 0.5rem;
		border-radius: 5px;
		color: black;
		background-color: #fff;
		border: 1px solid #ddd;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}

	.topHeading {
		font-weight: 600;
		color: rgb(0, 132, 48);
		font-size: small;
	}

	.image {
		width: 40px;
		height: 40px;
	}
}

.row1-row {
	margin-top: 1rem;
	&:first-child {
		margin-top: 1.3rem;
	}
	.left-text {
		font-weight: bold;
		margin-right: 5px;
	}
}
