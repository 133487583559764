.modalParentDiv {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 0.6rem;
	margin-bottom: 1rem;
}

.childDiv {
	display: flex;
	gap: 0.6rem;
}
