.addNewItemForm {
	padding: 2rem 0 9rem 2rem; // 9rem to balance ant design form padding bug
	width: 100%;
	height: 100vh;
	overflow: scroll;

	.form {
		width: 100%;
		min-width: 20rem;
		color: rgba(87, 87, 87, 1);
	}

	.productDetailsContainer {
		max-width: 870px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 4rem;
	}

	.childDetailsContainer {
		display: flex;
		flex-wrap: wrap;
		column-gap: 2rem;

		* {
			flex: 1;
		}
	}

	.parentDetailsContainer {
		margin: 12px 0px 20px 0px;
	}

	.stockDetailsContainer {
		display: flex;
		flex-wrap: wrap;
		column-gap: 2rem;

		* {
			flex: 1;
		}
	}

	.input {
		border-radius: 0.5rem !important;
		:global {
			.ant-input-prefix {
				margin-right: 10px;
				:local(svg) {
					color: rgba(87, 87, 87, 0.4);
				}
			}
		}
	}

	.select {
		border-radius: 0.5rem !important;
		max-width: 25.5rem;
	}

	.expandAddChild :hover {
		cursor: pointer;
	}

	.addNewSubItemIconContainer {
		margin: 1.25rem 0rem 2rem 0rem;
		text-align: center;
	}

	.addNewSubItemIcon {
		font-size: 2rem;
		:hover {
			cursor: pointer;
		}
	}

	.spinner {
		margin-left: 15rem;
	}

	.formItem {
		margin-top: 0;
		padding-top: 0;
		margin-bottom: 1.5rem;
	}
}
