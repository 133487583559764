.bankDetails {
	background-color: rgb(255, 255, 255);
	height: 100%;
	padding: 1rem;
	margin: 1rem 0.5rem;

	.form {
		max-width: 600px;
	}

	.info {
		font-size: 1rem;
		font-weight: 600;
		color: #c4c4c4;
		margin-left: 0.5rem;
	}

	.input {
		border-radius: 0.5rem !important;
		:global {
			.ant-input-prefix {
				margin-right: 10px;
				:local(svg) {
					color: rgba(87, 87, 87, 0.4);
				}
			}
		}
	}
}
