.skuDetail {
	.wrapper {
		display: flex;
		align-items: center;
		.sub {
			display: flex;
			flex: 1;
		}
	}
}
