.integrationsPage {
	padding: 2rem;

	.integrationCardsContainer {
		margin: 1.5rem 0rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1.5rem;
	}

	@media only screen and (max-width: 800px) {
		.integrationCardsContainer {
			grid-template-columns: 1fr;
		}
	}
}
