.attachmentForm {
	background-color: white;
	margin-left: 8px;
}

.warehousesList {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2rem;
	padding: 12px 12px 12px 8px;
	align-items: flex-start;
}

@media only screen and (max-width: 800px) {
	.warehousesList {
		grid-template-columns: 1fr;
		margin-bottom: 50px;
		width: 100%;
	}
}
