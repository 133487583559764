.profilePage {
	padding: 1rem 1.5rem;

	.title {
		font-weight: 600;
		margin-bottom: 2rem;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
