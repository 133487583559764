.verifyOtpPage {
	height: 100%;

	.leftComponent {
		background: linear-gradient(26.3deg, #0245a6 -35.69%, #63d7ff 103.01%);
	}
	.topLogoImage {
		top: 2rem;
		left: 2.5rem;
		position: absolute;
		height: 2rem;
	}
	.topImage {
		top: 0.8rem;
		right: 0.8rem;
		position: absolute;
		height: 4.5rem;
	}

	.buttonRow {
		align-self: flex-start;
		justify-content: space-around;

		button:nth-child(1) {
			margin-right: 10px;
		}
	}

	.leftTitle {
		color: white;
		font-size: 3.5rem;
		font-weight: 800;
		margin-top: 9rem;
		margin-left: 2.5rem;
		line-height: 4.5rem;
		@media (max-width: 1200px) {
			font-size: 2.8rem;
			line-height: 3.5rem;
		}
		:global {
			p {
				margin-bottom: 0;
			}
			.t2 {
				font-size: 1.4rem;
				font-weight: 500;
				:local(span) {
					color: #80f8e1;
				}
			}
		}
	}

	.verificationFormContainer {
		height: 100%;
		width: max-content;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		position: relative;
		top: 25%;

		.title {
			margin: 1rem 0;
		}

		.backBtn {
			align-self: flex-start;
			cursor: pointer;
			font-size: 24px;
		}

		.resendBtn {
			margin: 1rem 0;
			max-width: fit-content;
			display: flex;
			align-items: center;
		}

		.submitBtn {
			max-width: fit-content;
			padding: 0 3rem;
			position: relative;
			left: 70%;
		}
	}

	.inputStyle {
		width: 3rem !important;
		height: 3rem;
		margin: 0 1rem;
		font-size: 2rem;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.3);
	}
}
