.modalDiv {
	display: flex;
	margin-top: 1rem;
	flex-direction: column;
	padding: 1rem;
	font-size: 12px;
	background-color: #f6f6f6;
	border-radius: 4px;
	box-shadow: 0px 4px 4px rgb(199, 197, 197);
	h5 {
		font-size: 13px;
		font-weight: 700;
		margin: 0;
		padding: 0;
	}
	p,
	strong {
		font-size: 10px;
	}
	> :nth-child(1) {
		display: flex;
		align-items: center;
		column-gap: 2rem;
		text-align: left;
	}
}

#sideDrawer {
	display: none !important;
}
@media screen and (max-width: 700px) {
	#sideDrawer {
		display: block !important;
	}
}
