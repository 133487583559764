.leftComponent {
	background: linear-gradient(26.3deg, #0245a6 -35.69%, #63d7ff 103.01%);
}
.topLogoImage {
	top: 2rem;
	left: 2.5rem;
	position: absolute;
	height: 2rem;
}
.topImage {
	top: 0.8rem;
	right: 0.8rem;
	position: absolute;
	height: 4.5rem;
}
.loginComponent {
	display: flex !important;
	justify-content: center;
	padding: 1rem;
}
.formComponent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 600px;
}
.form {
	width: 100%;
	min-width: 20rem;
	margin-top: 3.5rem !important;
	color: rgba(87, 87, 87, 1);
}
.btn {
	margin-top: 3.5rem !important;
	text-align: center;
	:global {
		.ant-btn {
			width: 13rem;
			margin-right: 0.5rem;
			height: auto;
			font-size: 1.1rem;
			&:nth-child(2) {
				margin-right: 0;
				margin-left: 0.5rem;
			}
		}
	}
}
.title {
	font-weight: 800;
	font-size: 2.8rem;
}

.subTitle {
	font-weight: 400;
	font-size: 1rem;
	color: #6d6d6d;
}
.input {
	border-radius: 10px !important;
	font-size: 1.4rem !important;
	:global {
		.ant-input-prefix {
			margin-right: 10px;
			:local(svg) {
				color: rgba(87, 87, 87, 0.4);
			}
		}
	}
}

.leftTitle {
	color: white;
	font-size: 3.5rem;
	font-weight: 800;
	margin-top: 9rem;
	margin-left: 2.5rem;
	line-height: 4.5rem;
	@media (max-width: 1200px) {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}
	:global {
		p {
			margin-bottom: 0;
		}
		.t2 {
			font-size: 1.4rem;
			font-weight: 500;
			:local(span) {
				color: #80f8e1;
			}
		}
	}
}
.tabs {
	width: 80%;
}

@media screen and (max-width: 990px) {
	.leftComponent {
		display: none;
	}
	#loginPageMainDiv {
		overflow: scroll;
	}
	.btn {
		margin: 0;
		padding: 0;
		:global {
			.ant-btn {
				margin-right: 0;
				height: auto;
				font-size: 1.1rem;
				&:nth-child(2) {
					margin-right: 0;
					margin-left: 0;
				}
			}
		}
	}
	.formComponent {
		margin: auto;
	}
}
