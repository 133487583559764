.timelineText {
	display: flex;
}

.iconWrap {
	margin-top: -8.4px;
}

.filterDiv {
	display: flex;
	padding: 0.4rem;
	column-gap: 1rem;
	height: 2.8rem;
	padding-left: 1.4rem;
}

.filterParentDiv {
	border-bottom: 1px solid #f1eded;
	display: flex;
	align-items: center;
}
