.orderStatusFilter {
	display: flex;
	flex-direction: column;
	min-width: 250px;
}
.filterButton {
	font-size: 12px;
	font-weight: 700;
	padding: 3px 10px;
	justify-content: center;
	border-radius: 2px;
	float: right;
	margin-left: 1rem;
}
.buttonGroup {
	position: fixed;
	bottom: 20px;
	right: 20px;
}
