.integrationsCardOuterContainer {
	padding: 1.5rem 1rem;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	background-color: white;

	.integrationsCardPrimaryContent {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 1rem;
		height: 90px;
	}

	.integrationsCardSecondaryContent {
		margin-top: 2rem;

		.fieldContainer {
			margin-top: 1rem;
		}
	}

	.partnerImage {
		width: 85px;
		height: 85px;
		border-radius: 1rem;
		object-fit: contain;
	}

	.connectAccount {
		color: #d1d1d1;
		text-decoration: underline;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		height: 100%;
		width: 100%;

		.partnerName {
			margin-bottom: 0px;
			text-transform: capitalize;
		}

		.left,
		.right {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.right {
			align-items: flex-end;
		}
	}

	.submit {
		margin-top: 1rem;
		display: flex;
		justify-content: flex-end;
	}
}
