.searchContainer {
	flex-direction: column;
	display: flex;
	margin-right: 20px;
	height: auto;
}

.orderStatusFilter {
	display: flex;
	flex-direction: column;
	width: 200px;
	margin-right: 20px;
	border: unset;
	height: auto;
}
.orderStatusFilter > div {
	font-size: 12px !important;
}
.borderLessTag {
	border: none !important;
}

.filterButton {
	.filterImage {
		&:hover path {
			fill: #40a9ff !important;
		}
	}
}
sup {
	background: #096dd9 !important;
	color: #e6f7ff !important;
}
