.mainContainer {
	flex-grow: 1;
	background-color: rgba(240, 242, 245, 1);
	:global {
		.sideBar {
			border-right: 2px solid rgba(240, 242, 245, 1);
			padding: 0 0;
			display: flex;
			flex-direction: column;
			background-color: white;
		}
		.main {
			border-left: 2px solid rgba(240, 242, 245, 1);
			padding: 0;
			display: flex;
			flex-direction: column;
			height: 100%;
			.innerDiv {
				width: 100%;
				bottom: 0;
				padding: 12px 12px 12px 8px;
				flex-grow: 1;
				.div {
					background-color: white;
				}
			}
		}
	}
}
