.settingsPageContainer {
	max-height: 100vh;
	overflow: scroll;
	padding: 2rem 0;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}
