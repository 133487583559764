.main {
	position: fixed;
	top: 53px;
	bottom: 0;
	right: 0rem;
	width: 28rem;
	background: #ffffff;
	box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	transition: 0.5s linear;
	z-index: 2;

	.retailerContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.open {
		right: 0;
	}
	.paddedRow {
		padding: 1.4rem;
		padding-bottom: 0;
	}
	.row1 {
		margin-bottom: 2rem;
		:global {
			.title {
				font-size: 1.2rem;
			}
		}
	}
}
