.topHeader {
	padding: 1rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	margin-bottom: 1rem;
}

.contentPage {
	padding: 1.5rem 1.5rem 10rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	height: 100%;
}
.chargesDiv {
	margin-top: 1rem;
	margin-bottom: 2rem;
}
