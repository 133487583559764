.indicator {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 150ms linear 0s;
	opacity: 0;
	z-index: 0;
}

.loading {
	opacity: 1;
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 10;
}
.modalContent {
	border: unset !important;
}
