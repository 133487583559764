.uploadBulkModal {
	.heading {
		text-align: center;
		font-weight: 600;
	}

	.instructions {
		margin: 1.5rem 0;
	}

	.buttonsContainer,
	.fileDetails {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.uploadButton {
		display: none;
	}

	.uploadLabel {
		border: 1px solid #ccc;
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		margin-bottom: 0;
		color: #fff;
		background: #1890ff;
		border-color: #1890ff;
	}

	.fileName {
		margin: 0rem 0.5rem;
	}

	.errorList {
		list-style: none;
	}
}
