.main {
	position: fixed;
	top: 53px;
	bottom: 0;
	right: 0rem;
	width: 28rem;
	background: #ffffff;
	box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	transition: 0.5s linear;
	z-index: 2;

	.balanceDiv {
		width: 100%;
		background-color: white;
		padding: 0 1rem 0.5rem;
		display: flex;
		flex-direction: column;
	}

	.transactionInfoContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.open {
		right: 0;
	}

	.paddedDiv {
		padding: 1.4rem;
		padding-bottom: 0;
	}

	.transactionForm {
		margin-top: 20px;
	}
}
.topRow {
	margin-right: 20px;
}
