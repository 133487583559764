.outBoundButton {
	background-color: red;
	border: inherit;
}

.scanInventory {
	background-color: #fff;
	height: 100vh;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;

	.outerContainer {
		background-color: #e5e5e5;
	}
	.sidebar {
		display: flex;
		padding: 2rem;
		justify-content: center;
	}
	.topHeading {
		background-color: white;
		height: 9vh;
		width: 98%;
		margin: auto;
		margin-top: 0.6rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
	}
	.bottomTable {
		width: 98%;
		margin: auto;
	}
	.tableRow {
		height: 80vh;
		background-color: white;
		width: 98%;
		margin: auto;
		margin-top: 1rem;
		padding: 0;
	}
	.wsinInput {
		padding: 0.2rem 0.4rem 0.2rem;
		outline: none;
	}
	.inputBlink {
		border: 2px solid;
		animation: blink 1s;
		animation-iteration-count: infinite;
	}
	.topHeadingDiv {
		display: flex;
		align-items: center;
		column-gap: 1rem;
	}
}

@keyframes blink {
	50% {
		border-color: #ff0000;
	}
}
