.remittanceStatusFilter {
	display: flex;
	flex-direction: column;
	min-width: 200px;
	max-width: 200px;
	margin-right: 20px;
}
.topRow {
	margin-right: 20px;
}
