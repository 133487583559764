.oderId {
	color: #1890ff;
}

.skuText {
	overflow-wrap: break-word;
	color: #1890ff;
	cursor: pointer;
}
#hamburgForNavbar {
	display: none;
	position: absolute;
	top: 15px;
	left: 10px;
}
@media screen and (max-width: 990px) {
	#toMakeResponsiveSidebar {
		display: none;
	}
	#hamburgForNavbar {
		display: block;
	}
}
