.main {
	padding: 8rem 0;
	// padding: 12.5rem 0;

	.title {
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 2.0625rem;
		display: flex;
		align-items: center;
		color: #000000;
		margin-bottom: 1.5rem;
	}

	.card {
		background: #ffffff;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		margin-bottom: 1.5rem;
		padding: 1rem;
		border-radius: 0.25rem;
		text-align: center;
	}

	.companyName {
		font-style: normal;
		font-weight: 600;
		font-size: 1.125rem;
		line-height: 1.5625rem;
		display: flex;
		color: rgba(0, 0, 0, 0.85);
	}

	.value {
		margin-top: 1.5625rem;
		background: #e6f7ff;
		border-radius: 0.25rem;
		padding: 0 0.5rem;
		max-width: 2.75rem;
		font-style: normal;
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 2.0625rem;
		align-items: center;

		color: #1890ff;
	}
}
