.invoicingForm {
	margin: 1rem 0.5rem;
	padding: 1rem;
	height: 100%;
	background-color: rgb(255, 255, 255);
	display: flex;
	justify-content: space-between;

	.warehouseName {
		text-transform: capitalize;
	}

	.form {
		width: 80%;
		max-width: 600px;
		color: rgba(87, 87, 87, 1);
	}

	.addressContainer,
	.uploadContainer,
	.gridContainer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 3rem;
	}

	.uploadContainer {
		margin-bottom: 1rem;
	}

	.input {
		border-radius: 0.5rem !important;
		:global {
			.ant-input-prefix {
				margin-right: 10px;
				:local(svg) {
					color: rgba(87, 87, 87, 0.4);
				}
			}
		}
	}
}
@media screen and (max-width: 990px) {
	#addressContainer {
		display: flex;
		flex-direction: column;
	}
}

.box {
	display: inline-block;
	padding: 10px;
	box-sizing: border-box;
}
