.topFilter {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	& > div > * {
		margin-right: 1rem;
	}

	.topLeftFilter {
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		label {
			margin-bottom: 0.2rem;
			font-weight: 600;
		}
	}
	.topRightFilter {
		display: flex;
		margin-right: 10px;
	}
	.topRightFilter :last-child {
		margin-right: 0;
	}

	.searchInput {
		width: 350px;
	}
}

.searchContainer {
	display: flex;
	flex-direction: row;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.exportBtn {
	height: 1rem;
	display: flex;
	align-items: center;
	margin-left: 0.3rem;
	font-size: 0.9rem;
}
