.warehousesPage {
	height: 100vh;
	width: 100%;
	overflow-y: scroll;
	background-color: white;
	margin-top: -2rem;
	padding: 1rem 2rem;
	padding-bottom: 3rem;

	.warehousesList {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}

	.actionButtonsContainer {
		display: flex;
		margin: 2rem;
		max-width: 300px;
		column-gap: 2rem;
	}

	@media only screen and (max-width: 800px) {
		.warehousesList {
			grid-template-columns: 1fr;
			margin-bottom: 50px;
			width: 100%;
		}
		.actionButtonsContainer {
			flex-direction: column;
			gap: 10px;
		}
	}
}
