// this style not applying ?
.ant-drawer-close {
	//.anticon-close svg
	position: fixed !important;
	top: 20px !important;
	right: 20px !important;
}
.col {
	display: flex;
	flex-direction: column;
	margin: 0 5px;
	color: #808080;
	font-size: 12px;
	font-weight: 500;
}
.retailerTitle {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 20px;
	font-weight: 600;
}
.row {
	margin-top: 0.8rem;
}
.headingText {
	font-size: 13px;
	font-weight: 700;
}
.editButtonGroup {
	float: right;
	justify-content: space-between;
	display: flex;
	flex-direction: row;
	margin-right: 3px;
	align-items: center;
}
.inputElement {
	border-radius: 5px;
	width: 100%;
	margin-top: 0px;
}
.formItemInput .ant-form-item-label {
	font-size: 10px;
	padding: 0px !important;
}
// .ant-form-item-label.formItemInput{
//     padding: 0px !important;
//}
