.dynamicInputs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;
	margin-block: 0.5rem;
}

.inputField {
	width: 100%;
	flex: 1;
}
