.main {
	position: fixed;
	top: 45px;
	bottom: 0;
	width: 24rem;
	right: 0rem;
	background: #ffffff;
	box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
	transition: 0.5s linear;
	z-index: 2;

	.catalogueInfoContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.open {
		right: 0;
	}
	.paddedRow {
		padding: 1.4rem;
		padding-bottom: 0;
	}
	.editForm {
		margin: 2.5rem 0;
	}

	.row1 {
		margin: 2.5rem 0;
		.icon {
			font-size: 20px;
		}
		.button {
			border-radius: 5px;
		}
		.col {
			display: flex;
			flex-direction: column;
			margin: 0 5px;
		}
		:global {
			.title {
				font-size: 1.5rem;
			}
			.sub {
				color: rgba(120, 119, 119, 1);
				font-size: 0.9rem;
				svg {
					vertical-align: text-top;
				}
			}
			.row1-row {
				margin-top: 1rem;
				&:first-child {
					margin-top: 1.3rem;
				}
				.left-text {
					font-weight: bold;
					margin-right: 5px;
				}
			}
		}
	}
}
