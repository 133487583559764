.skuDetail {
	gap: 2px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	.tag {
		float: right;
		border-radius: 1rem;
		font-size: 0.7rem;
	}
	.wrapper {
		display: flex;
		padding-bottom: 20px;
		.sub {
			display: flex;
			flex: 1;
		}
	}
}

.wFull {
	width: 100%;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
