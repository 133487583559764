.page {
	padding: 1.5rem 1.5rem 10rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	height: 100vh;
	width: 100vw;
	display: flex;
}
.form {
	// border: 1px solid #e8e8e8;
	// border-radius: 4px;
	height: 70vh;
	overflow: auto;
	padding: 0 2rem 2rem 0;
}
