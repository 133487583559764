.main {
	.orderInfoContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.row4 {
		.icon {
			font-size: 16px;
			padding-top: 3px;
		}

		.form {
			width: 100%;
			min-width: 20rem;
			margin-top: 5px;
			color: rgba(87, 87, 87, 1);
			font-size: 12px;
		}

		.formItem {
			margin-bottom: 10px;
		}

		.input {
			border-radius: 10px;
			font-size: 12px;
			:global {
				.ant-input-prefix {
					margin-right: 10px;
					:local(svg) {
						color: rgba(87, 87, 87, 0.4);
					}
				}
			}
		}

		.button {
			height: 30px;
			padding: 0.4px 20px;
			font-size: 16px;
			border-radius: 40px;
			margin-top: 10px;
			margin-right: 10px;
		}

		:global {
			.title {
				font-size: 1.5rem;
			}
			.sub {
				color: rgba(120, 119, 119, 1);
				font-size: 0.9rem;
				svg {
					vertical-align: text-top;
				}
			}
			.row4-row {
				margin-top: 0.5rem;
				&:first-child {
					margin-top: 0;
				}
				.right-text {
					display: inline-block;
					width: 8rem;
				}
			}
		}
	}
}
