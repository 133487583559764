.addressPage {
	height: 100vh;
	width: 100%;
	overflow-y: hidden;
	background-color: white;
	margin-top: -2rem;
	padding: 1rem 2rem;
	padding-bottom: 3rem;

	.addressList {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}

	@media only screen and (max-width: 800px) {
		.addressList {
			grid-template-columns: 1fr;
			margin-bottom: 50px;
			width: 100%;
		}

		.actionButtonsContainer {
			flex-direction: column;
			gap: 10px;
		}
	}
}
.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
.actionButtonsContainer {
	max-width: 250px;
	margin-top: 1rem;
}
.searchContainer {
	display: flex;
	flex-direction: column;
}
span {
	font-weight: 600;
	align-items: left;
}

.paginationContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
	padding: 1rem;
	position: sticky;
	bottom: 8px;
	right: 20px;
	background-color: white;
}

.wrapperDiv {
	overflow-y: scroll;
	height: calc(100vh - 5rem);
	margin-bottom: 2rem;
}
