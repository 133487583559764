#leftComponent {
	background: linear-gradient(26.3deg, #0245a6 -35.69%, #63d7ff 103.01%);
	position: absolute;
	height: 1200px !important;
	padding: 20px;
}

.topLogoImage {
	top: 2rem;
	left: 2.5rem;
	position: absolute;
	height: 2rem;
}

.topImage {
	top: 0.8rem;
	right: 0.8rem;
	position: absolute;
	height: 4.5rem;
}

.loginComponent {
	display: flex !important;
	justify-content: center;
	padding: 2rem;
}

.title {
	font-weight: 800;
	font-size: 1.8rem;
}

.subTitle {
	margin: 2rem 3rem 0 3rem;
	font-weight: 400;
	font-size: 1.5rem;
}

.leftTitle {
	color: white;
	font-size: 2.5rem;
	font-weight: 800;
	margin-top: 9rem;
	margin-left: 2.5rem;
	line-height: 4.5rem;
	@media (max-width: 1200px) {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}
	:global {
		p {
			margin-bottom: 0;
		}
		.t2 {
			font-size: 1.4rem;
			font-weight: 500;
			:local(span) {
				color: #80f8e1;
			}
		}
	}
}

.profileFormContainer {
	margin: 3rem;
}

#signupContainer {
	position: absolute;
	right: 0;
	width: 100%;
}

@media screen and (max-width: 990px) {
	#leftComponent {
		display: none;
	}
}
