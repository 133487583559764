.cardStyles {
	width: 100%;
	height: 650px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		object-fit: contain;
		width: 100%;
	}
	> .chatDiv {
		width: 83%;
		height: 84%;
		margin-top: 1rem;
		border-radius: 1rem;
		position: absolute;
		display: flex;
		flex-direction: column;
		row-gap: 0.6rem;
		overflow: scroll;
		padding: 1rem;
		padding-right: 0.6rem;
		padding-top: 1.8rem;
		padding-bottom: 2rem;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
}

.messagesHeading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	& > :nth-child(1) {
		font-weight: bold;
		font-size: 12px;
		color: #fba011;
	}
	& > :nth-child(2) {
		font-size: 8px;
		color: #000;
	}
}

.messageParentDiv {
	width: 80%;
	padding: 0.5rem;
	font-weight: 500;
	& > :nth-child(2) {
		font-weight: 600;
		color: rgb(64, 64, 64);
		font-size: 11px;
	}
}
