.leftComponent {
	background: linear-gradient(26.3deg, #0245a6 -35.69%, #63d7ff 103.01%);
}
.topLogoImage {
	top: 2rem;
	left: 2.5rem;
	position: absolute;
	height: 2rem;
}

.rightComponent {
	display: flex !important;
	justify-content: center;
	padding: 1rem;
}
.formComponent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 600px;
}

.leftTitle {
	color: white;
	font-size: 3.5rem;
	font-weight: 800;
	margin-top: 9rem;
	margin-left: 2.5rem;
	line-height: 4.5rem;
	@media (max-width: 1200px) {
		font-size: 2.8rem;
		line-height: 3.5rem;
	}
	:global {
		p {
			margin-bottom: 0;
		}
		.t2 {
			font-size: 1.4rem;
			font-weight: 500;
			:local(span) {
				color: #80f8e1;
			}
		}
	}
}

.otpInputStyle {
	width: 3rem !important;
	height: 3rem;
	margin-right: 1rem;
	font-size: 2rem;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
}

.resendOTP {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}
