.colorPickerParentDiv {
	> :nth-child(1) {
		background-color: #4700d8;
	}
	> :nth-child(2) {
		background-color: black;
	}
	> :nth-child(3) {
		background-color: white;
	}
	> :nth-child(4) {
		background-color: #712b75;
	}
}

.colorPickerDiv {
	width: 34px;
	height: 34px;
	background-color: #b4ff9f;
	border-radius: 4px;
	display: flex;
	border: 1px solid rgb(198, 194, 194);
	align-items: center;
	justify-content: center;
	font-size: 26px;
	cursor: pointer;
}
