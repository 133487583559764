@import 'styles/_variables';

html {
	font-size: 14px;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $font-family-sans-serif;
}

#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.noDataDefaultComponent {
	font-size: 1.8rem;
	margin: auto;
	color: rgba(140, 135, 135, 1);
}

.scrollable {
	max-height: 100vh;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.closeBtn {
	position: absolute;
	margin: 0;
	padding: 0;
	top: 1rem;
	right: 1rem;
	color: rgba(48, 48, 48, 0.85);
	cursor: pointer;
	z-index: 2;
}
.closeBtn:hover {
	background-color: #d1d5db;
}

.linkify {
	color: #3656f4;
	text-decoration: underline;
	cursor: pointer;
}
