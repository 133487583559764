.bulkPreviewPage {
	padding: 2rem;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 1rem;

		.cancelButton {
			margin-right: 1rem;
		}
	}
}
