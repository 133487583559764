.main {
	height: 100vh;
	width: 100%;
	overflow-y: scroll;
	margin-top: -2rem;
	padding-bottom: 3rem;
	padding-top: 2rem;
}

.topFilter {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	& > div > * {
		margin-right: 1rem;
	}

	.topLeftFilter {
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		label {
			margin-bottom: 0.2rem;
			font-weight: 600;
		}
	}
	.topRightFilter {
		display: flex;
		margin-right: 10px;
	}
	.topRightFilter :last-child {
		margin-right: 0;
	}

	.searchInput {
		width: 350px;
	}
}

.searchContainer {
	display: flex;
	flex-direction: row;
}

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.addButton {
	margin-inline-start: 4rem !important;
	padding: 0.3rem 1rem !important;
	font-size: 0.8rem !important;
}
