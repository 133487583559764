.mainContainer {
	height: 100vh;
	overflow: scroll;

	.sidebar {
		background-color: #fff;
		padding: 0.8rem;
		padding-top: 3rem;
		.backButton {
			margin: auto;
			display: flex;
			align-items: center;
		}
		.sideDrawerBottomRow {
			margin-top: 1rem;
			display: flex;
			flex-direction: column;
			p {
				color: rgb(110, 110, 110);
				font-weight: 500;
			}
		}
	}
	.outerContainer {
		background-color: #e5e5e5;
		.tableRow {
			height: 80.8vh;
			overflow: scroll;
			background-color: white;
			width: 98%;
			margin: auto;
			margin-top: 0.7rem;
			padding: 0;
		}
	}
	.topHeading {
		background-color: white;
		height: 9vh;
		width: 98%;
		margin: auto;
		margin-top: 0.6rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
	}
	.disabledRow {
		background-color: #dcdcdc;
		pointer-events: none;
		color: #a7a7a7;
	}
	.wsinInput {
		padding: 0.2rem 0.4rem 0.2rem;
		outline: none;
	}
	.inputBlink {
		border: 2px solid;
		animation: blink 1s;
		animation-iteration-count: infinite;
	}
	@keyframes blink {
		50% {
			border-color: #ff0000;
		}
	}
	.redRow {
		background: #ff00001a;
	}
	.greenRow {
		background: #0cd60e26;
	}
	.blueRow {
		background: #faad1447;
	}
	.greenR {
		background-color: #95ff9561;
	}
}
