.select {
	border-radius: 0.5rem !important;
	max-width: 25.5rem;
}

.formItem {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 1.5rem;
}

.formLayoutItem {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 10px;
}
