.topFilter {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	.topLeftFilter {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		.searchFilter {
			margin-right: 20px;
		}
	}

	.searchInput {
		width: 350px;
	}
}

.buttonRightMargin {
	margin-right: 10px;
}
