.pickupAddressPage {
	padding: 1rem;

	.title {
		font-weight: 600;
	}

	.pickupAddressesList {
		margin: 1.5rem 0rem;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 2rem;
		column-gap: 1.5rem;
	}
}
