.addressCard {
	width: 400px;
	height: 220px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	display: flex;
	column-gap: 1rem;
	background-color: rgb(255, 255, 255);

	.addressId {
		width: 50px;
		height: 50px;
		border-radius: 0.5rem;
		display: grid;
		place-items: center;
		font-size: 1.5rem;
		font-weight: 600;
		color: #454545;
		background: #f4f4f4;

		&.active {
			color: #1890ff;
			background: #e6f7ff;
		}
	}

	.warehouseName {
		font-weight: 600;
		font-size: 1.2rem;
	}

	.content {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.address {
		margin-top: 0rem;
		line-height: 1.1rem;
		font-size: 0.75rem;
		color: #454545;

		* {
			margin: 0.5rem 0;
		}
	}

	.options {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
	}
}
