.topHeader {
	padding: 1rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	margin-bottom: 1rem;
}
.contentPage {
	padding: 1.5rem 1.5rem 10rem 1.5rem;
	overflow: auto;
	flex-basis: inherit;
	background-color: white;
	height: 100%;
}
.beatConfig {
	display: flex;
	justify-content: space-around;
	margin-bottom: 5rem;
}

.uploadBulkModal {
	.heading {
		text-align: center;
		font-weight: 600;
	}

	.instructions {
		margin: 1.5rem 0;
	}

	.buttonsContainer,
	.fileDetails {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.uploadButton {
		display: none;
	}

	.uploadLabel {
		border: 1px solid #ccc;
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
		margin-bottom: 0;
		color: #fff;
		background: #1890ff;
		border-color: #1890ff;
	}

	.fileName {
		margin: 0rem 0.5rem;
	}

	.errorList {
		list-style: none;
	}
}
